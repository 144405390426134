
import firebase from 'utils/firebase'


export default async function getFunction(funcName) {
	const { getFunctions, httpsCallable } = await import('firebase/functions')
	console.log(firebase)
	const functions = getFunctions(firebase)
	const func = httpsCallable(functions, funcName)
	console.log(func)
	return func
	// await Promise.all(rooms.map(async (room) => leaveRoomFunc({ roomId: room.id })))
}