
import React from 'react'
import { getAuth, updatePassword } from 'firebase/auth'

import firebase from 'utils/firebase'
import useStatus from 'utils/useStatus'
import Spinner from 'utils/Spinner'
import SignInEmail from 'user/SignInEmail'
import SignInFacebook from 'user/SignInFacebook'


export default function ChangePassword() {

	const inputRef = React.useRef(null)
	const [status, setStatus] = useStatus()
	const [relogin, setRelogin] = React.useState(false)

	const handleSubmit = async (event) => {
		event.preventDefault()
		setStatus(true)
		try {
			const auth = getAuth(firebase)
			await updatePassword(auth, inputRef.current.value)
			setStatus({ success: true })
		} catch (error) {
			switch (error.code) {
				case 'auth/requires-recent-login':
					setRelogin(true)
					setStatus()
					break
				default:
					setStatus({ error })
					break
			}
		}
	}

	return <form onSubmit={handleSubmit}>
			
		<h2>Change password</h2>

		{ status.error }

		{ relogin && <>
			<p>This operation requires recent authentication.</p>
			<SignInEmail />
			<SignInFacebook />
		</> }

		{ (!relogin && !status.success) && <>
			<label>
				New password:
				<input type='password' 
					id="accChangePassword" 
					aria-labelledby="accChangePassword" 
					autoComplete='new-password' 
					ref={inputRef}
				/>
			</label>
			<button type='submit' disabled={status.working}>
				Change password
			</button>
		</> }

		{ status.success && <p>Password changed.</p> }

		{ status.working && <Spinner>Working...</Spinner> }

	</form>
}