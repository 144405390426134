
import React from 'react'


export default function useStatus() {

	const [status, setStatus] = React.useState({
		working: false,
		success: null,
		warning: null,
		error: null,
	})

	const setStatusReducer = React.useCallback(action => {

		if (!action) {

			setStatus({
				working: false,
				success: null,
				warning: null,
				error: null,
			})

		} else if (action === true) {

			setStatus({
				working: true,
				success: null,
				warning: null,
				error: null,
			})

		} else if (action.success) {

			setStatus({
				working: false,
				success: action.success,
				warning: null,
				error: null,
			})

		} else if (action.warning) {

			setStatus({
				working: false,
				success: false,
				warning: <div className='warning'>{action.warning}</div>,
				error: null,
			})

		} else if (action.error) {

			let errorText = typeof action.error === 'string'
				?	action.error
				:	action.error.message ?? "Unknown error"

			if (errorText === 'INTERNAL') errorText = "Unknown internal error."

			setStatus({
				working: false,
				success: false,
				warning: null,
				error: <div className='error'>{ errorText }</div>
			})

		} else {
			setStatus(prev => ({ ...prev, ...action }))
		}

	}, [setStatus])

	return [status, setStatusReducer]
}