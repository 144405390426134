
import React from 'react'
import { useRecoilValue } from 'recoil'
// import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, deleteUser } from 'firebase/auth'
import { navigate } from 'gatsby'

import getFunction from 'utils/functions'
import firebase from 'utils/firebase'
import useStatus from 'utils/useStatus'
import Spinner from 'utils/Spinner'
import SignInEmail from 'user/SignInEmail'
import SignInFacebook from 'user/SignInFacebook'

import { roomsAtom } from 'utils/atoms'


export default function DeleteAccount() {

	const rooms = useRecoilValue(roomsAtom)
	const [status, setStatus] = useStatus()
	const [relogin, setRelogin] = React.useState(false)

	async function handleDelete(event) {
		event.preventDefault()
		if (!window.confirm("Do you really want to delete your account?")) return false
		try {
			if (Array.isArray(rooms) && rooms.length) {
				setStatus({ working: "Leaving rooms", error: null })
				// const functions = getFunctions(firebase)
				// const leaveRoomFunc = httpsCallable(functions, 'leaveRoom')
				const leaveRoomFunc = await getFunction('leaveRoom')
				await Promise.all(rooms.map(async (room) => leaveRoomFunc({ roomId: room.id })))
			}
			setStatus({ working: "Deleting account" })
			const auth = getAuth(firebase)
			await deleteUser(auth.currentUser)
			navigate('/signout/')
		} catch (error) {
			console.error(error)
			switch (error.code) {
				case 'auth/requires-recent-login':
					setRelogin(true)
					setStatus()
					break
				default:
					setStatus({ error })
					break
			}
		}
	}

	function handleRelogin() {
		setRelogin(false)
	}

	return <>

		<h2>Delete account</h2>

		<p>Leave all rooms and remove all data associated with your account.</p>

		{ !relogin && <p><button onClick={handleDelete} disabled={status.working}>
			Delete account
		</button></p> }

		{ relogin && <>
			<p>This operation requires recent authentication.</p>
			<SignInEmail done={handleRelogin} />
			<SignInFacebook done={handleRelogin} />
		</> }

		{ status.working && <Spinner>{status.working}</Spinner> }

		{ status.error }

	</>
}