
import React from 'react'
import { Link } from 'gatsby'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useRecoilValue } from 'recoil'

import firebase from 'utils/firebase'
import Spinner from 'utils/Spinner'
import useStatus from 'utils/useStatus'

import { userAtom } from 'utils/atoms'


export default function SignInEmail({
	done = () => {}
}) {

	const [status, setStatus] = useStatus()
	const emailRef = React.useRef(null)	
	const passwordRef = React.useRef(null)
	const { isSigningIn } = useRecoilValue(userAtom)

	async function handleSubmit(event) {
		event.preventDefault()
		const formData = new FormData(event.target)
		const email = formData.get('email'),
				password = formData.get('password')
		setStatus(true)
		try {
			const auth = getAuth(firebase)
			await signInWithEmailAndPassword(auth, email, password)
			done()
		} catch (error) { 
			switch (error.code) {
				case 'auth/wrong-password':
					setStatus({ error: "Wrong password." })
					break
				default:
					setStatus({ error }) 
					break
			}
		}
	}

	return <form onSubmit={handleSubmit}>

		<h2>Sign in with email and password</h2>

		<label>
			Email:
			<input 
				name='email'
				type="email"
				autoComplete='username'
				ref={emailRef}
				required
			/>
		</label>

		<label>
			Password:
			<input
				name='password'
				type='password'
				autoComplete='current-password'
				ref={passwordRef}
				required
			/>
		</label>

		<p>
			{ (status.working || isSigningIn)
				?	<Spinner>Signing in</Spinner>
				:	<button
						disabled={status.working || isSigningIn}
						type='submit'
					>
						Sign In
					</button>
			}
		</p>

		<p><Link to='/forgot/'>Forgot your password?</Link></p>

		{ status.error }

	</form>
}
