
import React from 'react'
import { Link } from 'gatsby'

import Meta from 'layout/Meta'
import ReqAuth from 'user/ReqAuth'
import DeleteAccount from 'user/DeleteAccount'
import ChangePassword from 'user/ChangePassword'


export default function AccountPageAuth() {
	return <>

		<Meta title="Account" />
	
		<ReqAuth>

			<main>

				<h1>Account</h1>

				<p>
					<Link to='/signout/' className='button'>Sign out</Link> 
				</p>

				<br />

				<ChangePassword />

				<br />

				<DeleteAccount />

			</main>
			
		</ReqAuth>

	</>
}
