
import React from 'react'


export default function Spinner({ 
	children,
	fullscreen = false
}) {

	if (fullscreen) return <main><div><span className='spinner' /> { children }</div></main>

	return !children
		?	<span className='spinner' />
		:	<span className='spinnerLabel'>
				<span className='spinner' />
				<span className='spinnerText'>{ children }</span>
			</span>
}
