
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Link } from 'gatsby'

import SignInEmail from 'user/SignInEmail'
import SignInFacebook from 'user/SignInFacebook'

import { userAtom } from 'utils/atoms'


export default function ReqAuth({ children }) {

	const { isAuth } = useRecoilValue(userAtom)

	if (isAuth) return children

	return <main>

		<SignInEmail />
		<SignInFacebook />

		<Link to='/signup/'>Create account</Link>

	</main>
}
