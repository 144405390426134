
import React from 'react'
import { getAuth, FacebookAuthProvider, linkWithPopup, signInWithPopup } from 'firebase/auth'
import { useRecoilState } from 'recoil'

import firebase from 'utils/firebase'
import Symbol from 'utils/Symbol'

import { userAtom } from 'utils/atoms'


export default function SignUpFacebook({ done = () => {} }) {

	const [error, setError] = React.useState(null)
	const [{ isFacebookAuth }, setUser] = useRecoilState(userAtom)


	async function handleSignIn(event) {
		event.preventDefault()
		try {
			setError(null)
			const auth = getAuth(firebase)
			const provider = new FacebookAuthProvider()
			if (!!auth.currentUser) {
				await linkWithPopup(auth.currentUser, provider)
			} else {
				await signInWithPopup(auth, provider)
			}
			setUser(prev => ({ ...prev, isFacebookAuth: true }))
			// done()
		} catch (error) {
			switch (error.code) {
				case 'auth/account-exists-with-different-credential':
					setError(`Looks like you've already registered with your email and password. Sign in with that account first and then connect your Facebook profile.`)
					break
				case 'auth/credential-already-in-use':
					setError(`Looks like you already have a Nameless account with this Facebook profile. Sign out and sign in again using Facebook.`)
					break
				case 'auth/email-already-in-use':
					setError(`Looks like you already have a Nameless account created with the same email address as your Facebook account. Sign in using that account to join them.`)
					break
				default:
					setError(`${error.message} [${error.code}]`)
					break
			}
		}
	}

	React.useEffect(() => {
		console.log('isFbAuth', isFacebookAuth)
	}, [isFacebookAuth])

	return <>

		<h2>Sign in with Facebook</h2>

		<p>
			<button onClick={handleSignIn}>
				<Symbol id='Facebook' /> Sign in with Facebook
			</button>
		</p>
		
		{ !!error && <div className='error'>{ error }</div> }

	</>
}
